import Glide, {Controls, Breakpoints} from "@glidejs/glide/entry/entry-complete";


const glideCarousel = () => {
    new Glide('[data-glide-carousel]', {
        perView: 1,
        autoplay: 3000
    }).mount()
}


const modal = function (modal) {
    const buttonsOpen = document.querySelectorAll('[data-modal-open]');
    const buttonClose = document.querySelector('[data-modal-close]');
    const classOpen = 'af-modal--open';
    const classVisually = 'af-modal--visually';
    const modalComponent = modal;
    const modalContent = modal.querySelector('[data-modal-content]');

    const openModal = (event) => {
        event.preventDefault();
        const parent = event.target.closest('[data-modal-open');
        const heading = parent.querySelector('[data-modal-title]').cloneNode(true);
        const paragraph = parent.nextElementSibling.cloneNode(true);
        // Asignar clase al body para bloquearlo y crear el overlay
        document.body.classList.add(classOpen);
        // Meter el contenido
        modalContent.append(heading);
        modalContent.append(paragraph);
        // Mostrar la modal
        modalComponent.classList.add(classVisually);

    }
    const closeAndEmptyModal = (event) => {
        document.body.classList.remove(classOpen);
        modalComponent.classList.remove(classVisually);
        while(modalContent.firstElementChild){
            modalContent.removeChild(modalContent.firstElementChild);
        }
    }
    buttonsOpen.forEach(btn => {
        btn.addEventListener('click', openModal.bind(this), false)
    })
    buttonClose.addEventListener('click', closeAndEmptyModal.bind(this), false);

}

const displayMenu = () => {
    const btn = document.querySelector('[data-menu-button]');
    const menu = document.querySelector('[data-menu]');
    let isMenuActive = false;
    const controllerStateMenu = (stateMenu)=> {
        btn.setAttribute('aria-expanded', (stateMenu) ? 'false': 'true');
        menu.classList.toggle('af-menu--open');
        document.body.classList.toggle('af-modal--open');
    }
    btn.addEventListener('click', () => {
        controllerStateMenu(isMenuActive);
        isMenuActive = !isMenuActive;
    })

    menu.addEventListener('click', (event)=> {
        event.preventDefault();
        if(event.target.hash) {
            const navigateToElement = document.querySelector(event.target.hash);
            controllerStateMenu(isMenuActive);
            navigateToElement.scrollIntoView({behavior: 'smooth'});
            isMenuActive = !isMenuActive;
        }
    })
}
const goToMain = () => {
    const link = document.querySelector('[data-link-goto]')
    const main = document.querySelector('#home');
    link.addEventListener('click', (event)=> {
        event.preventDefault();
        main.scrollIntoView({behavior: 'smooth'});
    })
}

window.addEventListener('load', () => {
    const modalElement = document.querySelector('[data-modal]');
    glideCarousel();
    modal(modalElement);
    displayMenu();
    goToMain();
})

